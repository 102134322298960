  /** @type {import('tailwindcss').Config} */ 
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('react-responsive-carousel/lib/styles/carousel.min.css');
@import url('react-modern-drawer/dist/index.css');
@import url('react-phone-input-2/lib/style.css');
@import url('react-fontpicker-ts/dist/index.css');
@import url('simplebar-react/dist/simplebar.min.css');

/* Basic CSS for apps built with Ionic */
@import url('@ionic/react/css/normalize.css');
@import url('@ionic/react/css/structure.css');
@import url('@ionic/react/css/typography.css');

/* Optional CSS utils that can be commented out */
@import url('@ionic/react/css/padding.css');
@import url('@ionic/react/css/float-elements.css');
@import url('@ionic/react/css/text-alignment.css');
@import url('@ionic/react/css/text-transformation.css');
@import url('@ionic/react/css/flex-utils.css');
@import url('@ionic/react/css/display.css');
@import url('@ionic/react/css/core.css');


@import url('./shadows.css');
@import url('./react-day-picker.css');
@import url('./panel.css');

body {
  @apply text-black;
}

.ion-padding {
  @apply px-2 md:px-4;
}

.layout-main-section {
  @apply bg-white/70;

  /* &.scroll-effect-normal {} */

  &.scroll-effect-snap-proximity {
    &::part(scroll) {
      scroll-snap-type: y proximity;
    }
    &::part(scroll) > * {
      scroll-snap-align: start;
    }
  }

  &.scroll-effect-snap-mandatory {
    &::part(scroll) {
      scroll-snap-type: y mandatory;
    }
    &::part(scroll) > * {
      scroll-snap-align: start;
    }
  }
}

.container-width {
  @apply sm:max-w-lg md:max-w-2xl lg:max-w-3xl xl:max-w-4xl mx-auto;
}

.container-width-wide {
  @apply sm:max-w-xl md:max-w-5xl lg:max-w-6xl mx-auto;
}

.text-priority-higth {
  @apply text-xs xs:text-sm sm:text-base md:text-lg;
}

.text-priority-normal {
  @apply text-[10px] xs:text-xs sm:text-sm md:text-base;
}

.shaked {
  animation: shake 0.5s ease;
  animation-iteration-count: 3;
  animation-delay: 2.5s;
}
.shaked-2s {
  @apply shaked;
  animation-delay: 2.4s;
}

.shaked-cascade-1 {
  @apply shaked;
  animation-iteration-count: 1;
  animation-delay: 1s;
}

.shaked-cascade-2 {
  @apply shaked;
  animation-iteration-count: 1;
  animation-delay: 1.6s;
}

.shaked-cascade-3 {
  @apply shaked;
  animation-iteration-count: 1;
  animation-delay: 2.4s;
}


@keyframes shake {
	0%, 100% {transform: translateX(0);}
	20%, 60%, 100% {transform: translateX(-3px);}
	40%, 80% {transform: translateX(3px);}
}

.spin-slow {
  animation:spin 60s linear infinite;
}

@keyframes spin {
	100% { transform: rotate(360deg); }
}


/*
image Grid
*/
.slider-container {
  @apply rounded-md;
}

.images-grid {
  @apply grid grid-cols-3 md:grid-cols-4 gap-1 xs:gap-2 sm:gap-4 lg:gap-6;
  /* card */
  .image-card {
    @apply flex flex-col bg-white border border-stone-300 mb-1.5 md:mb-3 cursor-pointer;
    img, .img-holder {
      @apply h-20 xs:h-28 sm:h-36 w-full object-cover rounded-md;
    }
    .img-holder {
      @apply bg-stone-200;
    }
    .meta {
      @apply px-0.5 py-1 sm:px-1 sm:py-1.5 w-full;
    }
    h3 {
      @apply text-stone-700 text-xs sm:text-base font-semibold leading-4;
    }
    .price {
      @apply text-green-700 mt-1 font-mono text-xs sm:text-sm;
    }
  }
  .selected {
    @apply border-2 border-amber-200 scale-[1.01] opacity-20;
  }
}
/* grid sizes */
/* xs */
.images-grid.xs {
  @apply grid grid-cols-4 md:grid-cols-5 gap-2 md:gap-3 lg:gap-4;
  .image-card {
    @apply md:mb-1;
    img, .img-holder {
      @apply h-14 xs:h-16 sm:h-24;
    }
  }
}
/* xxs */
.images-grid.xxs {
  @apply grid grid-cols-4 gap-2 md:gap-1;
  .image-card {
    @apply md:mb-1;
    img, .img-holder {
      @apply h-14 xs:h-16 sm:h-20 md:h-16;
    }
  }
}
/* vertical */
.images-grid.vertical {
  @apply grid grid-cols-1 gap-2 md:gap-1;
  .image-card {
    @apply md:mb-1;
    img, .img-holder {
      @apply h-24 sm:h-32 md:h-40;
    }
  }
}
/* image in cart */
.image-card.in-cart { 
  @apply border-[3px] border-lime-500;
}
.image-card.in-cart.xs { 
  @apply border-[2px] border-lime-500;
}


/* Cupertino pane */

.cupertino-pane-wrapper {
  width: 100%;
  height: 100%;

  .pane  {
    z-index: 300;
    padding: 0px;
    overflow: hidden;
  }
  .backdrop {
    z-index: 49;
  }  
}

.drawer-details .cupertino-pane-wrapper.rendered .pane,
.drawer-details .cupertino-pane-wrapper.rendered .pane .draggable,
.drawer-details .cupertino-pane-wrapper.rendered .pane .destroy-button {
  z-index: 310;
} 

.drawer-details .cupertino-pane-wrapper.rendered .pane .destroy-button {
  top: 5px;
}

@screen lg {
  .drawer-details .cupertino-pane-wrapper.rendered .pane {
    max-width: 700px;
  } 
}


/* Day Picker */

.rdp .rdp-months {
  justify-content: space-around;
}

.rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover {
  @apply bg-brand-primary !important;
}

.react-tel-input .form-control {
  @apply !w-full py-4 !text-base !shadow !border-gray-200;
}
.react-tel-input .selected-flag  {
  @apply !border-gray-100;
}
.react-tel-input .country {
  @apply text-left;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none border border-brand-primary rounded-sm;
}
.rdp-caption_label {
  font-size: 14px !important;
  z-index: unset !important;
}

.p-inputwrapper input {
  max-width: 100%;
  background-color: transparent;
}


/* Form wizard */

.react-form-wizard {
  .wizard-header {
    @apply !pt-0;
  }
  
  .wizard-tab-content {
    @apply !min-h-[55vh];
  }
  
  .form-wizard-steps {
    a > div, .wizard-icon-container {
      @apply !border-brand-secondary !bg-brand-secondary;
      .wizard-icon {
        @apply text-brand-secondary-contrast;
      }
    }
    a > span {
      @apply !text-brand-secondary-contrast !bg-brand-secondary px-4 py-1.5 rounded-md;
    }
    li.active {
      a > div, .wizard-icon-container {
        @apply !border-brand-primary !bg-brand-primary;
      }
      a > span {
        @apply !text-brand-primary-contrast !bg-brand-primary;
      }
    }
  }

  .wizard-progress-bar {
    @apply !text-brand-primary !border-brand-primary !bg-brand-primary;
  }
  
  .wizard-card-footer {
    @apply w-full fixed left-0 bottom-0 !py-2 border-t border-gray-200 bg-white;
  }
  
  .wizard-card-footer .wizard-btn {
    @apply !text-xs;
  }
  
  .wizard-nav .stepTitle  {
    @apply !text-sm;
  }
}

ion-button ion-avatar { 
  width: 26px;  
  height: 26px;  
}


/* 
TODO fix

@media screen {
  .print-only {
    visibility: hidden;
    display: none;
  }
}

@media print {
  .print-only {
    visibility: unset;
    display: block;
  }
} */


/* main menu transition */

.transition-opacity-90, .transition-opacity-full, .transition-opacity-none {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.transition-opacity-90 {
  opacity: 0.9;
  visibility: visible;
}
.transition-opacity-full {
  opacity: 1;
  visibility: visible;
}
.transition-opacity-none {
  opacity: 0;
  visibility: hidden;
}


/* beating heart */
@keyframes beatHeart {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.beating {
  -webkit-animation: .9s infinite beatHeart;
          animation: .9s infinite beatHeart;
}


/**
 * Thumb image format
 */
 .vertical-square {
  @apply !h-[170px] !min-h-[170px] md:!h-[190px] md:!min-h-[190px] lg:!h-[200px] lg:!min-h-[200px] xl:!h-[240px] xl:!min-h-[240px];
}

.vertical-wide {
  @apply !h-[340px] !min-h-[340px] md:!h-[360px] md:!min-h-[360px] lg:!h-[380px] lg:!min-h-[380px] xl:!h-[400px] xl:!min-h-[400px];
}

/**
 * Font Picker
 */
.fontpicker {
  @apply !bg-gray-400 rounded border-gray-900 py-1.5;
  .fontpicker__popout, .fontpicker__search {
    @apply !bg-gray-400;
  }
  .fontpicker__option {
    @apply !bg-gray-300;
  }
  .fontpicker__option:hover {
    @apply !bg-gray-400;
  }
}


/**
 * Notion
 */
.yoopta-editor {
  @apply !pb-0 font-brand-secondary;

  [data-floating-ui-portal] > div {
    @apply z-[200];
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-brand-main;
  }
}